$mobileWidth: 45em;
$black: black;
$brightText: #C3C3C3;
$primary: #8E8E8E;

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-weight: 400;
}

@font-face {
    font-family: 'Open Sans';
    src: url(./OpenSans-VariableFont_wdth\,wght.ttf);
}

@font-face {
    font-family: 'Inter';
    src: url(./Inter-VariableFont_slnt,wght.ttf);
}

html {
    background-color: $black;
}

.app {
  background-color: $black;
  height: 95vh;
  color: $primary;
  margin: 0;
  font-family: 'Open Sans';
  font-weight: 400;
}
.landing-page {
    display: flex;
    flex-direction: column;
    height: 80vh;
    margin-top: 5vh;
    justify-content: space-between;
    align-items: center;

    .logo {
        color: $brightText;
        font-size: 40px;
    }
    .slogan {
        font-size: 32px;
        width: 50vw;
        text-align: center;
    }
    @media (max-width: $mobileWidth) {
        .slogan {
            width: 80vw;
            font-size: 28px;
        }
    }
    .dragger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25vw;
        height: 27vh;

        .inert-photo-icon {
            color: $primary;
            width: 6vw;
            height: 6vw;
        }
        @media (max-width: $mobileWidth) {
            .inert-photo-icon {
                width: 20vw;
                height: 20vw;
            }
        }
        
        .ant-upload-text {
            color: $primary;
        }

        .ant-upload-drag {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-radius: 3vw;
            background-color: $black;

            .ant-upload-btn {
                height: 100%;
                width: 100%;
                border-radius: 3vw;
                border-width: 10px;

                .ant-upload-drag-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            @media (max-width: $mobileWidth) {
                .ant-upload-btn {
                    border-radius: 10vw;
                }
            }
        }
        @media (max-width: $mobileWidth) {
            .ant-upload-drag {
                border-radius: 10vw;
            }
        }
    }
    @media (max-width: $mobileWidth) {
        .dragger {
            width: 80vw;
        }
    }
}

.ant-modal-mask {
    backdrop-filter: blur(2px);
}

.image-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .image-preview {
        max-width: 100%;
        max-height: 60vh;
        border-radius: 10px;
    }
}
.num-of-colors-slider {
    display: flex;
    gap: 30px;
}
.slider {
    flex-grow: 5;
}

.palette-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-around;
    align-items: center;

    .logo {
        color: $brightText;
        font-size: 40px;
        cursor: pointer;
    }

    .image-palette-container {
        display: flex;
        flex-direction: column;
        max-width: 50vw;
        max-height: 65vh;
        height: fit-content;

        .palette-image {
            max-width: 100%;
            max-height: 85%;
            min-height: 50%;
            height: auto;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        
        .palette {
            display: flex;
            container-type: size;
            max-width: 100%;
            min-height: 15%;
            :last-child {
                border-bottom-right-radius: 10px;
            }
            :first-child {
                border-bottom-left-radius: 10px;
            }
    
            .palette-color {
                flex-grow: 1;
                container-type: size;
                
                .palette-color-text-tooltip {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .palette-color-text {
                    font-family: 'Inter';
                    font-weight: 600;
                    font-size: calc(8px + 0.5vw);
                }
                @container (max-width: 90px) {
                    .palette-color-text {
                        font-size: calc(8px + 0.3vw);
                    }
                }
                @container (max-width: 70px) {
                    .palette-color-text {
                      font-size: 0;
                    }
                }
                @media (max-width: $mobileWidth) {
                    .palette-color-text {
                        font-size: 0;
                    }
                }
            }
            
            .palette-color-dark {
                color: white;
            }
            .palette-color-bright {
                color: black;
            }
        }
    }
    @media (max-width: $mobileWidth) {
        .image-palette-container {
            max-width: 90vw;
            max-height: 65vh;
        }
    }
    .palette-page-buttons {
        width: 60vw;
        min-width: fit-content;
        display: flex;
        justify-content: space-around;

        .palette-page-button {
            background-color: $brightText;
            color: $black;
            min-width: 10vw;
        }
    }
    @media (max-width: $mobileWidth) {
        .palette-page-buttons {
            height: 15vh;
            width: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .palette-page-button {
                background-color: $brightText;
                color: $black;
                width: 10em;
            }
            .download-button {
                display: none;
            }
        }
    }

}
